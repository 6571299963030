import React from "react"
import { Card, CardBody, CardHeader, Container } from "reactstrap"

const AboutMe = () => {
  return (
    <Container id="about" className="mt-3 mb-5">
      <Card
        className="mx-auto"
        color="primary"
        outline
        style={{
          maxWidth: 800,
        }}
      >
        <CardBody>
          <h3 className="text-center underline">About ME</h3>
          <p className="p-3 description">
            Hi, My name is Ashad Nasim. I'm a full stack web developer who is
            passionate to learn new web technologies and framework. I have 2
            years of experience working with MERN Stack and MEAN Stack. I had
            build some excellent projects like <strong>mSyllabus</strong>,{" "}
            <strong>ekLnk</strong> and <strong>adconsole</strong>, etc . <br />{" "}
            I'm also a <strong>Mobile App Developer</strong>. I had also built
            some great apps like <strong>Simple Video Editor</strong>,{" "}
            <strong>Video Status Splitter</strong>, <strong>myTodo</strong>, etc
            which are in playstore, feel free to use them. <br /> Currently I
            work mostly with Javascript technologies like ReactJS, React Native,
            and NodeJS. I also have hands-on experience operating with cloud
            infrastructures such as AWS have apps deployed that take scalability
            into account. I'm always a learner and a self taught programmer.
          </p>
        </CardBody>
      </Card>
    </Container>
  )
}

export default AboutMe
