import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import HeroSection from "../components/home/HeroSection"
import AboutMe from "../components/home/AboutMe"
import Projects from "../components/home/Projects"
import Experience from "../components/home/Experience"
import Contact from "../components/home/Contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    <Projects />
    <Experience />
    <AboutMe />
    <Contact />
    {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
  </Layout>
)

export default IndexPage
