import React, { useState } from "react"
import {
  Badge,
  Col,
  Container,
  Row,
  Button,
  Card,
  CardColumns,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  CardDeck,
} from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FiExternalLink } from "react-icons/fi"

import Tilt from "react-parallax-tilt"
import ItemsCarousel from "react-items-carousel"
import { FiChevronsRight, FiChevronsLeft } from "react-icons/fi"
import { useMediaQuery } from "react-responsive"

const Projects = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [windowWidth, setwindowWidth] = useState(0)
  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveItemIndex(activeItemIndex => activeItemIndex + 1)
    }, 6000)
    var width = window.outerWidth
    setwindowWidth(width)

    window.addEventListener("resize", data => {
      var w = window.outerWidth
      // var h = window.outerHeight
      setwindowWidth(w)
    })
    return () => {
      clearInterval(interval)
      window.removeEventListener("resize", () => {})
    }
  }, [])
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "msyllabus.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
            tracedSVG
          }
        }
      }
      ranchi_motors: file(relativePath: { eq: "ranchi_motors.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
            tracedSVG
          }
        }
      }
      adconsole: file(relativePath: { eq: "adconsole.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
            tracedSVG
          }
        }
      }

      appImages: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "apps" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 90) {
                base64
                srcSet
                src
                aspectRatio
                srcWebp
                srcSetWebp
                originalName
                presentationHeight
                presentationWidth
              }
            }
          }
        }
      }

      todo_icon: file(relativePath: { eq: "app_icons/todo.png" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
            tracedSVG
          }
        }
      }
      video_splitter: file(
        relativePath: { eq: "app_icons/video_splitter.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
            tracedSVG
          }
        }
      }
      videoeditor: file(relativePath: { eq: "app_icons/videoeditor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
            tracedSVG
          }
        }
      }
    }
  `)

  console.log(data)

  const calcCard = dim => {
    console.log(dim)

    if (dim >= 1072) {
      console.log(3)

      return 3
    } else if (dim >= 850) {
      console.log(2)

      return 2
    } else {
      console.log(1)

      return 1
    }
  }

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  })

  return (
    <Container className="mt-5 mb-5 project" id="projects">
      <h1 className="bigTiltle text-center text-uppercase pt-3 pb-5 mb-3">
        <span className="underline">Recent Web Projects</span>
      </h1>
      <Row className="mt-4 mb-4 pt-3 pb-3">
        <Col md="6" className="d-flex  align-items-center mx-auto">
          <div className="">
            <div className="underline mb-3">
              <span className="title">mSyllabus</span>{" "}
            </div>
            <p className="project-title">
              A website for teacher to take live classes, manage students, fee
              with reminder and student attendence
            </p>
            <div>
              <a
                href="https://msyllabus.com/"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary  actionButton"
                role="button"
              >
                Visit Website <FiExternalLink className="ml-2" />
              </a>{" "}
            </div>
          </div>
        </Col>
        <Col md="6">
          <Tilt scale={1.15} transitionSpeed={2500}>
            <Img
              loading="eager"
              fluid={data.placeholderImage.childImageSharp.fluid}
              className="img-fluid image"
            />
          </Tilt>
        </Col>
      </Row>
      <Row className="mt-4 mb-4 pt-3 pb-3">
        <Col md="6" className="d-flex  align-items-center mx-auto">
          <div className="">
            <div className="underline mb-3">
              <span className="title">Ranchi Motors Website</span>{" "}
            </div>
            <p className="project-title">
              Ranchi Motors, a company of mine friend, for which I had created a
              website with admin panel to add and remove products
            </p>
            <div>
              <a
                href="https://ranchimotors.com/"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary  actionButton"
                role="button"
              >
                Visit Website <FiExternalLink className="ml-2" />
              </a>{" "}
            </div>
          </div>
        </Col>{" "}
        <Col md="6">
          <Tilt scale={1.15} transitionSpeed={2500}>
            <Img
              loading="eager"
              fluid={data.ranchi_motors.childImageSharp.fluid}
              className="img-fluid image"
            />
          </Tilt>
        </Col>
      </Row>
      <Row className="mt-4 mb-4 pt-3 pb-3">
        <Col md="6" className="d-flex  align-items-center mx-auto">
          <div className="">
            <div className="underline mb-3">
              <span className="title">adConsole</span>{" "}
            </div>
            <p className="project-title">
              It is a Place where You can Find Your Next Sponsor For Your Brand,
              in seconds
            </p>
            <div>
              <a
                href="https://adconsole.in/"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary  actionButton"
                role="button"
              >
                Visit Website <FiExternalLink className="ml-2" />
              </a>{" "}
            </div>
          </div>
        </Col>
        <Col md="6">
          <Tilt scale={1.15} transitionSpeed={2500}>
            <Img
              loading="eager"
              fluid={data.adconsole.childImageSharp.fluid}
              className="img-fluid image"
            />
          </Tilt>
        </Col>
      </Row>
      <h2 className="bigTiltle text-center text-uppercase pt-3 pb-3 mb-2 mt-5">
        <span className="underline">Recent App ScreenShot</span>
      </h2>{" "}
      <Row>
        <Col md="12">
          {isTabletOrMobileDevice ? (
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              gutter={20}
              infiniteLoop={true}
              showSlither={true}
              activePosition="center"
              numberOfCards={calcCard(windowWidth)}
            >
              {data.appImages.edges.map(edge => (
                <div
                  style={{
                    width: 250,
                  }}
                >
                  <Img
                    fluid={edge.node.childImageSharp.fluid}
                    className="img-fluid "
                    style={{ width: "100%" }}
                  />
                  <p className="text-center appName text-uppercase">
                    {
                      String(
                        edge.node.childImageSharp.fluid.originalName
                      ).split(".")[0]
                    }
                  </p>
                </div>
              ))}
            </ItemsCarousel>
          ) : (
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              gutter={20}
              infiniteLoop={true}
              leftChevron={<FiChevronsLeft className="icon" />}
              rightChevron={<FiChevronsRight className="icon" />}
              outsideChevron
              showSlither={true}
              activePosition="center"
              numberOfCards={calcCard(windowWidth)}
              chevronWidth={40}
            >
              {data.appImages.edges.map(edge => (
                <div
                  style={{
                    width: 250,
                  }}
                >
                  <Img
                    fluid={edge.node.childImageSharp.fluid}
                    className="img-fluid "
                    style={{ width: "100%" }}
                  />
                  <p className="text-center appName text-uppercase">
                    {
                      String(
                        edge.node.childImageSharp.fluid.originalName
                      ).split(".")[0]
                    }
                  </p>
                </div>
              ))}
            </ItemsCarousel>
          )}
        </Col>
      </Row>
      <div>
        <h3 className="bigTiltle text-center text-uppercase pt-3 pb-3 mb-4 mt-5">
          <span className="underline">Some of the Best apps</span>
        </h3>
        <div>
          <CardDeck numberOfCards={3}>
            <Card>
              <div className="img-small card-img-top">
                <Img
                  fluid={data.video_splitter.childImageSharp.fluid}
                  className="w-100"
                />
              </div>
              <CardBody className="text-center">
                <CardTitle tag="h5">Video Splitter App</CardTitle>
                <CardSubtitle className="text-black-50">
                  10,000+ Installs
                </CardSubtitle>
                <CardText className="text-monospace pt-3">
                  This app is used to split long video into the segment to share
                  on Social Sites.
                </CardText>
                <a
                  class="btn btn-primary btn-block"
                  href="https://play.google.com/store/apps/details?id=videosplitter.app.status.socialmedia.sharing"
                  role="button"
                >
                  See in Google PlayStore <FiExternalLink className="ml-2" />
                </a>
              </CardBody>
            </Card>
            <Card>
              <div className="img-small card-img-top">
                <Img
                  fluid={data.todo_icon.childImageSharp.fluid}
                  className="w-100"
                />
              </div>
              <CardBody className="text-center">
                <CardTitle tag="h5">myTODO App</CardTitle>
                <CardSubtitle className="text-black-50">
                  Published on 31 December 2020
                </CardSubtitle>

                <CardText className="text-monospace pt-3">
                  This app is a simple and best Todo manager for your daliy
                  tasks.
                </CardText>
                <a
                  class="btn btn-primary btn-block"
                  href="https://play.google.com/store/apps/details?id=todo.com.todo.my"
                  role="button"
                >
                  See in Google PlayStore <FiExternalLink className="ml-2" />
                </a>
              </CardBody>
            </Card>
            <Card>
              <div className="img-small card-img-top">
                <Img
                  fluid={data.videoeditor.childImageSharp.fluid}
                  className="w-100"
                />
              </div>
              <CardBody className="text-center">
                <CardTitle tag="h5">Simple Video Editor</CardTitle>
                <CardSubtitle className="text-black-50">
                  100+ Installs
                </CardSubtitle>
                <CardText className="text-monospace pt-3">
                  This is a simple video editor app which can complete your
                  specific task in a click.
                </CardText>
                <a
                  class="btn btn-primary btn-block"
                  href="https://play.google.com/store/apps/details?id=com.simple.video.editor"
                  role="button"
                >
                  See in Google PlayStore <FiExternalLink className="ml-2" />
                </a>
              </CardBody>
            </Card>
          </CardDeck>
        </div>
      </div>
    </Container>
  )
}

export default Projects

// https://portfolio.smakosh.com/
// https://gatsby-simplefolio.netlify.app/
