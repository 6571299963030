import React from "react"
import { Card, CardText, CardTitle, Col, Row } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const experr = [
  {
    commanyName: "LearnCodeOnline (2nd Internship)",
    role: "Software ​ Engineer​ Intern",
    type: "Remote",
    description: `My task was to create projects using various technologies and to help students when they have problems.`,
  },
  {
    commanyName: "Sankshi Private Limited​",
    role: "Mobile & Backend Intern",
    type: "Remote",

    description: `My job was to develop an app called aaina.me (using React Native) and to construct a backend using Nodejs.`,
  },
  {
    commanyName: "LearnCodeOnline",
    role: "Web development Intern",
    type: "Remote",
    description: `My responsibilities were revamping the main domain from the existing tech stack
    into a faster speed website. Involves usage of react, graphql and other JS based
    technologies.`,
  },
  {
    commanyName: "Feelin​",
    role: "Software Engineer Intern",
    type: "",

    description: `During this internship my responsibilities was to work in the app (using React Native)
    and the website
    WorkingArea : ReactJS, React-Native and NodeJS​.`,
  },
]

const Experience = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "web_developer.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `)
  return (
    <div className="container">
      <h3 className="bigTiltle text-center text-uppercase pt-3 pb-3 mb-4 mt-5">
        <span className="underline">my Experience</span>
      </h3>
      <Row>
        <Col md="7">
          <div class="timeline">
            {experr.map(singleExp => (
              <div className="expe">
                <span className="title">{singleExp.commanyName}</span>
                <span className="subtitle">
                  {singleExp.type} {singleExp.role}
                </span>
                <p>{singleExp.description}</p>
              </div>
            ))}
          </div>
        </Col>

        <Col
          md="5"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="w-100">
            <Img
              loading="eager"
              fluid={data.placeholderImage.childImageSharp.fluid}
              className="img-fluid"
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Experience
