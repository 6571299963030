import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Resume from "../../assets/resume.pdf"
import { FiDownload } from "react-icons/fi"
import { Link as ReactScroll } from "react-scroll"

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "coding_.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `)

  return (
    <Container className="pt-5 pb-5 pt-md-1">
      <Row>
        <Col md="7" className="d-flex  align-items-center">
          <div>
            <h1 className="font-weight-bolder pb-3">
              Hi, my name is <span className="underline">Ashad Nasim</span>
            </h1>
            <h2 className="mt-2 mb-5">
              I'm a Full Stack engineer, Android Devloper <br /> and a
              passionate learner
            </h2>
            <div>
              <Button
                tag={ReactScroll}
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
                color="primary"
                className="actionButton"
              >
                More About Me
              </Button>

              <a
                class="btn btn-outline-primary space-l actionButtonOutline"
                href={Resume}
                download
                role="button"
              >
                {" "}
                Download Resume <FiDownload />
              </a>
            </div>
          </div>
        </Col>
        <Col md="5">
          <Img
            loading="eager"
            fluid={data.placeholderImage.childImageSharp.fluid}
            className="img-fluid img-space"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default HeroSection
