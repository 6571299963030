import React from "react"
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "mail.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            base64
            srcSet
            src
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `)

  return (
    <Container
      id="contact"
      className="contact w-100 mt-3 mb-3 d-flex align-items-center  justify-content-center flex-column"
    >
      <h4 className="bigTiltle text-center text-uppercase pt-3 pb-3 mb-4 mt-5">
        <span className="underline">Some Great Idea, Let's Discuss</span>
      </h4>{" "}
      <Row className="w-100">
        <Col md="5" className="p-3">
          <Img
            loading="eager"
            fluid={data.placeholderImage.childImageSharp.fluid}
            className="img-fluid"
          />
        </Col>
        <Col md="7" className="p-4">
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input type="text" name="name" id="name" required />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">Email</Label>
                  <Input type="email" name="email" id="email" required />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="body">Subject</Label>
              <Input
                type="text"
                name="subject"
                id="subject"
                aria-rowcount="5"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="body">Body</Label>

              <Input type="textarea" name="text" id="exampleText" required />
            </FormGroup>
            <FormGroup className="text-center">
              <Button
                className="btn btn-primary actionButton btn-block"
                type="submit"
                color="primary"
              >
                Send
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Contact
